import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import reportApi from "api/reportApi";

const thunkGetReport = createAsyncThunk("report", async (params) => {
  return await reportApi.getReport(params);
});

const reportSlice = createSlice({
  name: "report",
  initialState: {
    speaker: null,
    totalDevices: null,
    activeDevices: null,
    errorDevices: null,
    playingDevices: null,
    totalMembers: null,
    activeMembers: null,
    bulletinsCount: null,
    bulletinsTotalSize: null,
    bulletinsTotalTime: null,
    loadingState: true,
    radioStationCount: null,
    bulletins: null,
    bulletinsDetail: null,
    bulletinBoard: null,
    bulletinHistoryCount: null
  },
  reducers: {},
  extraReducers: {
    [thunkGetReport.fulfilled]: (state, action) => {
      state.loadingState = false;
      if (action.payload.data.result == "success") {
        state.totalDevices = action.payload.data.report.speaker.total;
        state.activeDevices = action.payload.data.report.speaker.online;
        state.errorDevices = action.payload.data.report.speaker.offline;
        state.radioStationCount = action.payload.data.report.radioStationCount;
        state.totalMembers = action.payload.data.report.userCount;
        state.activeMembers = action.payload.data.report.userActive;
        state.bulletinsCount = action.payload.data.report.bulletins.bulletinsCount;
        state.bulletinsTotalSize = action.payload.data.report.bulletins.bulletinsTotalSize;
        state.bulletinsTotalTime = action.payload.data.report.bulletins.bulletinsTotalTime;
        state.bulletinHistoryCount = action.payload.data.report.bulletins.bulletinHistoryCount;
        state.bulletins = action.payload.data.report.bulletins;
        state.bulletinsDetail = action.payload.data.report.bulletinsDetail;
        state.bulletinBoard = action.payload.data.report.bulletinBoard;
      }
    },
    [thunkGetReport.pending]: (state, action) => {
      state.loadingState = true;
    },
    [thunkGetReport.rejected]: (state, action) => {
      state.loadingState = false;
    },
  },
});

const { reducer } = reportSlice;

export default reducer;
export { thunkGetReport };
